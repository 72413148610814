import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormSelect from '../../../../components/FormSelect';
import FormCheckbox from '../../../../components/FormCheckbox';

function DefaultProcessesRechnungenDetailsView() {

	let { orderId } = useParams();
	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(0);
	const [showLoader, setShowLoader] = useState(true);
	const [billDetails, setBillDetails] = useState([]);

	const [showEditOrderStatusModal, setShowEditOrderStatusModal] = useState(false);
	const [newOrderStatus, setNewOrderStatus] = useState("is_paid");
	
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const [showCancelOrderBillModal, setShowCancelOrderBillModal] = useState(false);

	useEffect(() => {

		axios.get(`${process.env.REACT_APP_API_URL}/api/bills/${orderId}`).then((res) => {
			console.log(res.data[0]);
			setBillDetails(res.data[0]);
			setShowLoader(false);
		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error");
		})

	}, [orderId, reloadKey]);

	const updateBillStatus = () => {

		setShowLoader(true);

		if (newOrderStatus === "") {
			setShowLoader(false);
			return;
		}

		axios.put(`${process.env.REACT_APP_API_URL}/api/bills/status/${orderId}/${newOrderStatus}`).then((res) => {
			console.log(res.data);
			setShowEditOrderStatusModal(false);

			if (res.data.status === "success") {
				setShowLoader(false);
				navigate.push("/status/success");
			} else {
				setShowLoader(false);
				navigate.push("/status/error");
			}

		}).catch((err) => {
			setShowEditOrderStatusModal(false)
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	const cancelBill = () => {

		setShowLoader(true);

		axios.put(`${process.env.REACT_APP_API_URL}/api/bills/status/${orderId}/cancelled`).then((res) => {
			console.log(res.data);
			setShowCancelOrderModal(false);

			if (res.data.status === "success") {
				setShowLoader(false);
				setReloadKey(reloadKey + 1);
				setShowCancelOrderBillModal(true)
			} else {
				setShowLoader(false);
				navigate.push("/status/error");
			}

		}).catch((err) => {
			setShowCancelOrderModal(false);
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	const createCancellationBill = () => {

		setShowLoader(true);

		axios.post(`${process.env.REACT_APP_API_URL}/api/bills/cancellation/${orderId}`).then((res) => {
			console.log(res.data);
			if (res.data.status === "success") {
				setShowLoader(false);
				setShowCancelOrderBillModal(false);
				navigate.push("/default-processes/rechnungen/" + res.data.bill_id);
			} else {
				setShowLoader(false);
				navigate.push("/status/error");
			}
		}).catch((err) => {
			setShowCancelOrderModal(false);
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	const downloadFile = () => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/bills/pdf/${orderId}`).then((res) => {
			let archiveFileId = res.data;

			axios.get(`${process.env.REACT_APP_API_URL}/api/archive/5/${archiveFileId}`, {
				responseType: 'blob'
			})
			.then((res) => {
				setShowLoader(false);

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', billDetails.bill_identifier + ".pdf");
				document.body.appendChild(link);
				link.click();											
			}).catch((err) => {
				setShowLoader(false);
				navigate.push("/status/error");
			})

		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	const formatCurrency = (value) => {
		value = value.toString();
		value = value.replace('€', '');
		value = value.replace(' ', '');

		
		if (value !== "" && !(value).includes('NaN')) {
			value = value.replace(' EUR', '');
			value = value.replace('&nbsp;', ' ');

			// Create our number formatter.
			var formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
			});

			value = formatter.format((value).replace(',', '.'));

			return value;
		}

		return value;

	}

	const editBill = () => {
		let invoicePositions = [];

		billDetails.positions.forEach((position) => {
			invoicePositions.push({
				sku: position.bill_item_sku || "",
				name: position.bill_item_description || "",
				tax: position.bill_item_tax_rate || "0",
				count: position.bill_item_quantity || 1,
				unit_price: formatCurrency(position.bill_item_amount) || "0,00 €",
				total: formatCurrency(position.bill_item_total_amount) || "0,00 €"
			})
		})
		
		// Store all input values into local storage to prevent data loss
		let localStorageData = {
			editBillId: billDetails.id,
			customerNoInput: (billDetails.bill_recipient_customer_id == 0 ? "" : billDetails.bill_recipient_customer_id),
			nameInput: billDetails.bill_recipient_name || "",
			addressInput: billDetails.bill_recipient_address_line_1 || "",
			address2Input: billDetails.bill_recipient_address_line_2 || "",
			postcodeInput: billDetails.bill_recipient_zip || "",
			cityInput: billDetails.bill_recipient_city || "",
			invoiceNoInput: billDetails.bill_identifier || "",
			valutaInput: new Date(billDetails.bill_date).toLocaleDateString("de-DE", {
																								year: "numeric",
																								month: "2-digit",
																								day: "2-digit",
																							}) || "",
			bookingTypeSelect: billDetails.bill_type || "",
			periodicFirstInvoiceDateInput: "",
			invoicePositions: invoicePositions,
			paidCheckbox: billDetails.bill_is_paid === 1 ? true : false,
			internalAccountSelect: "",
			paymentMethodSelect: billDetails.payment.bill_payment_method_id || "",
			paymentDeadlineInput: new Date(billDetails.payment.bill_payment_till_date).toLocaleDateString("de-DE", {
																								year: "numeric",
																								month: "2-digit",
																								day: "2-digit",
																							}) || "",
			paymentGiroCodeCheckbox: billDetails.payment.bill_payment_show_girocode === 1 ? true : false,
			internalTransferSollAccount: "",
			internalTransferHabenAccount: "",
			internalTransferAmount: billDetails.bill_total || 0
		}
		localStorage.setItem("billData", JSON.stringify(localStorageData));
		localStorage.setItem("editingBill", billDetails.bill_identifier);
		navigate.push("/default-processes/manuelle-rechnung");
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="orders-1.jpg"
					title={
						billDetails.bill_identifier !== undefined ? "Rechnung " + billDetails.bill_identifier : "Rechnung wird geladen..."
					}
					text="Verwalten Sie Ihre Rechnungen und erhalten Sie eine Übersicht."

					hasCancelButton={false}

					actionButtons={[
						{
							title: "Status ändern",
							action: () => {
								setShowEditOrderStatusModal(true);
							},
							hidden: billDetails.bill_is_cancelled === 1
						},
						{
							title: "Rechnung stornieren",
							action: () => {
								setShowCancelOrderModal(true)
							},
							hidden: billDetails.bill_is_cancelled === 1
						},
						{
							title: "Rechnung bearbeiten",
							action: editBill,
							hidden: (billDetails.bill_is_cancelled === 1 || billDetails.customer === null)
						},
						{
							title: "Druckausgabe erzeugen",
							action: () => {
								downloadFile();
							}
						},
					]}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/rechnungen")
					}}
				/>

				<ProcessSideContent>
					{billDetails.bill_is_cancelled ? (
						billDetails.bill_is_cancelled === 1 ? (
							<ContentBox title="Hinweis!" warning>
								<p>Diese Buchung {billDetails.bill_identifier} wurde storniert!</p>
							</ContentBox>
						) : (null)
					) : (null)}
					<ContentBox title="Details:">
						<div style={{
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							gap: '20px'
						}}>
							<table className="table-view table-view--half">
								<tbody>
									<tr>
										<td className="table-view-title">
											Beleg-Nr.:
										</td>
										<td className="table-view-content">
											{billDetails.bill_identifier}
										</td>
									</tr>
									<tr>
										<td className="table-view-title">
											Beleg vom:
										</td>
										<td className="table-view-content">
											{new Date(billDetails.bill_date).toLocaleDateString("de-DE", {
																								year: "numeric",
																								month: "2-digit",
																								day: "2-digit",
																							})}
										</td>
									</tr>
									<tr>
										<td className="table-view-title">
											Beleg-Art:
										</td>
										<td className="table-view-content">
											{
												(billDetails.bill_type === "outgoing_invoice") ? "Ausgehende Rechnung" : 
												(billDetails.bill_type === "incoming_invoice") ? "Eingehende Rechnung" :
												(billDetails.bill_type === "internal_transfer") ? "Interne Umbuchung" :
												(billDetails.bill_type === "cancellation") ? "Stornobuchung" :
												(billDetails.bill_type === "offer") ? "Angebot" :
												(billDetails.bill_type === "periodic") ? "Periodische Buchung (mtl.)" : "Unbekannte buchung"
											}
										</td>
									</tr>
									<tr>
										<td className="table-view-title">
											Status:
										</td>
										<td className="table-view-content">
											{
												billDetails.bill_is_paid === 1 ? "Bezahlt" : "Offener Betrag"
											}
										</td>
									</tr>
								</tbody>
							</table>
							<table className="table-view table-view--half">
								<tbody>
									<tr>
										<td className="table-view-title">
											Kunde:
										</td>
										<td className="table-view-content" style={{whiteSpace: 'pre-line'}}>
											{ billDetails.bill_recipient_customer_id > 0 && (`${billDetails.bill_recipient_customer_id}, `) }
											{ billDetails.bill_recipient_name !== "" && (`${billDetails.bill_recipient_name}`) }
										</td>
									</tr>
									<tr>
										<td className="table-view-title" style={{border:"none"}}>
											Adresszusammenfassung:
										</td>
										<td className="table-view-content" style={{border:"none",whiteSpace: 'pre-line'}}></td>
									</tr>
									<tr>
										<td className="table-view-content" style={{textAlign:"left",whiteSpace: 'pre-line'}}>
											{ (billDetails.bill_recipient_name !== "" && billDetails.bill_recipient_name !== "./.") && (`${billDetails.bill_recipient_name}\n`) }
											{ (billDetails.bill_recipient_address_line_1 !== "" && billDetails.bill_recipient_address_line_1 !== "./.") && (`${billDetails.bill_recipient_address_line_1}\n`) }
											{ (billDetails.bill_recipient_address_line_2 !== "" && billDetails.bill_recipient_address_line_2 !== "./.") && (`${billDetails.bill_recipient_address_line_2}\n`) }
											{ (billDetails.bill_recipient_state !== "" && billDetails.bill_recipient_state !== "./.") && (`${billDetails.bill_recipient_state}\n`) }
											{ (billDetails.bill_recipient_country !== "" && billDetails.bill_recipient_country !== "./.") && (`${billDetails.bill_recipient_country}-`) }
											{ (billDetails.bill_recipient_zip !== "" && billDetails.bill_recipient_zip !== "./.") && (`${billDetails.bill_recipient_zip} `) }
											{ (billDetails.bill_recipient_city !== "" && billDetails.bill_recipient_city !== "./.") && (`${billDetails.bill_recipient_city}\n`) }
										</td>
										<td className="table-view-title"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</ContentBox>
					<ContentBox title="Positionen:">
						<table className="table-view table-view--left-aligned">
							<thead>
								<tr>
									<th className="table-view-title">Pos.</th>
									<th className="table-view-title">Art.-Nr.</th>
									<th className="table-view-title">Bez.</th>
									<th className="table-view-title" style={{textAlign:'right'}}>Anzahl</th>
									<th className="table-view-title" style={{textAlign:'right'}}>Stk. Preis</th>
									<th className="table-view-title" style={{textAlign:'right'}}>Steuersatz</th>
									<th className="table-view-title" style={{textAlign:'right'}}>Ges. Preis</th>
								</tr>
							</thead>
							<tbody>
								{
									billDetails.positions && (
										billDetails.positions.map(position => (
											<tr key={position.id}>
												<td className="table-view-content">
													{position.bill_item_position}
												</td>
												<td className="table-view-content">
													{position.bill_item_sku}
												</td>
												<td className="table-view-content">
													{position.bill_item_description}
												</td>
												<td className="table-view-content" style={{textAlign:'right'}}>
													{position.bill_item_quantity}
												</td>
												<td className="table-view-content" style={{textAlign:'right'}}>
													{
														(position.bill_item_total_amount) && (
															formatCurrency(position.bill_item_amount)
														)
													}
												</td>
												<td className="table-view-content" style={{textAlign:'right'}}>
													{
														(position.bill_item_tax_rate) && (
															parseFloat(position.bill_item_tax_rate).toFixed(2).toString().replace(".", ",")
														)
													} %
												</td>
												<td className="table-view-content" style={{textAlign:'right'}}>
													{
														(position.bill_item_total_amount) && (
															formatCurrency(position.bill_item_total_amount)
														)
													}
												</td>
											</tr>
										))
									)
								}
							</tbody>
						</table>
					</ContentBox>
					<ContentBox title="Rechnung:">
						<table className="table-view">
							<tbody>
								<tr>
									<td className="table-view-title">
										Netto:
									</td>
									<td className="table-view-content">
										{
											(billDetails.bill_total) && (
												formatCurrency((billDetails.bill_total - billDetails.bill_tax))
											)
										}
									</td>
								</tr>
								<tr>
									<td className="table-view-title">
										Davon Steuer:
									</td>
									<td className="table-view-content">
										{
											(billDetails.bill_total) && (
												formatCurrency(billDetails.bill_tax)
											)
										}
									</td>
								</tr>
								<tr>
									<td className="table-view-title table-view-title--highlighted">
										In Rechnung gestellt:
									</td>
									<td className="table-view-content table-view-content--highlighted">
										{
											(billDetails.bill_total) && (
												formatCurrency(billDetails.bill_total)
											)
										}
									</td>
								</tr>
							</tbody>
						</table>
					</ContentBox>
				</ProcessSideContent>
			</Content>

			{showEditOrderStatusModal && (
				<Modal
					title="Status ändern"
					isClosable={true}
					closeAction={() => {
						setShowEditOrderStatusModal(false);
					}}

					actionButtons={[
						{
							title: "Speichern",
							action: () => {
								updateBillStatus();
							}
						}
					]}
				>
					<FormSelect
						label="Neuer Status"
						value={newOrderStatus}
						onChange={(e) => {
							setNewOrderStatus(e.target.value);
						}}
						options={[
							{ value: "is_paid", label: "Zahlung abgeschlossen" },
							{ value: "is_not_paid", label: "Zahlung ausstehend" }
						]}
					/>
				</Modal>
			)}

			{showCancelOrderModal && (
				<Modal
					title="Rechnung stornieren"
					isClosable={true}
					closeAction={() => {
						setShowCancelOrderModal(false);
					}}

					actionButtons={[
						{
							title: "Stornieren",
							type: "danger",
							action: () => {
								cancelBill()
							}
						}
					]}
				>
					<p>
						Sind Sie sicher, dass Sie die Rechnung stornieren möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.
					</p>
				</Modal>
			)}

			{showCancelOrderBillModal && (
				<Modal
					title="Rechnung storniert!"
					isClosable={true}
					closeAction={() => {
						setShowCancelOrderBillModal(false);
					}}

					actionButtons={[
						{
							title: "Storno-Buchung anlegen",
							type: "danger",
							action: () => {
								createCancellationBill()
							}
						}
					]}
				>
					<p>
						Die Rechnung {billDetails.bill_identifier} wurde erfolgreich storniert. Möchten Sie eine Storno-Buchung anlegen?
					</p>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesRechnungenDetailsView;