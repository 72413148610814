import React, {useState} from 'react';
import {
	Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom"; 
import { history } from './utils/history';
import RouteGuard from "./utils/RouteGuard";
import {setAuthToken} from "./utils/setAuthToken";
import axios from 'axios';

// Styles
import './scss/styles.scss';

// Components
import Header from './components/Header';
import Loader from './components/Loader';

// Views
import LoginView from './views/Login';
import HomeView from './views/Home';

import SystemInformationView from './views/SystemInformationView';
import ProcessStatusView from './views/ProcessStatusView';

import DefaultProcessesProdukteView from './views/DefaultProcesses/Produkte';
import DefaultProcessesProdukteCreateView from './views/DefaultProcesses/Produkte/Create';
import DefaultProcessesProdukteEditView from './views/DefaultProcesses/Produkte/Edit';

import DefaultProcessesKundenView from './views/DefaultProcesses/Kunden';
import DefaultProcessesKundenCreateView from './views/DefaultProcesses/Kunden/Create';
import DefaultProcessesKundenEditView from './views/DefaultProcesses/Kunden/Edit';

import DefaultProcessesBuchungenView from './views/DefaultProcesses/Buchungen';
import DefaultProcessesInterneKontenView from './views/DefaultProcesses/InterneKonten';

import DefaultProcessesManuelleRechnungView from './views/DefaultProcesses/ManuelleRechnung';
import DefaultProcessesDeveloperView from './views/DefaultProcesses/Developer';
import DefaultProcessesDeveloperFormsView from './views/DefaultProcesses/Developer/Forms';

import DefaultProcessesRechnungenView from './views/DefaultProcesses/Rechnungen';
import DefaultProcessesRechnungenDetailsView from './views/DefaultProcesses/Rechnungen/Details';
import DefaultProcessesRechnungenEditView from './views/DefaultProcesses/Rechnungen/Edit';

import DefaultProcessesArchivView from './views/DefaultProcesses/Archiv';
import DefaultProcessesArchivFolderView from './views/DefaultProcesses/Archiv/Folder';

import DefaultProcessesServiceCenter from './views/DefaultProcesses/ServiceCenter';

import DefaultProcessesVerwaltungView from './views/DefaultProcesses/Verwaltung';
import DefaultProcessesVerwaltungBenutzerView from './views/DefaultProcesses/Verwaltung/Benutzer';
import DefaultProcessesVerwaltungJahresabschlussView from './views/DefaultProcesses/Verwaltung/Jahresabschluss';
import DefaultProcessesVerwaltungBenutzerCreateView from './views/DefaultProcesses/Verwaltung/Benutzer/Create';
import DefaultProcessesVerwaltungBenutzerDetailsView from './views/DefaultProcesses/Verwaltung/Benutzer/Details';
import DefaultProcessesVerwaltungBenutzerCreatePermissionView from './views/DefaultProcesses/Verwaltung/Benutzer/CreatePermission';

import CustomProcessesKfzKalkulatorView from './views/CustomProcesses/KfzKalkulator';

import { useEffect } from 'react';

function App() {

	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setAuthToken(token);
			
			axios.get(`${process.env.REACT_APP_API_URL}/api/me`).then((res) => {
				if (res.data.data) {
					// Check if the user has a role
					setIsLoading(false);
					setIsAuthenticated(true);
					localStorage.setItem("data", JSON.stringify(res.data.data));
				} else {
					setIsLoading(false);
					doLogout();
				}
			})
			.catch((error) => {
				setIsLoading(false);
				doLogout();
			})
		} else {
			history.push("/login");
			setIsLoading(false);
		}
	})

	const doLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("data");
		history.push("/login");
	}

	return (
		isLoading ? (<Loader />) : (
			<Router history={history}>
				<Header isAuthenticated={isAuthenticated} />
				<Switch>
					<RouteGuard exact path="/" component={() => <HomeView />} />
					<RouteGuard exact path="/default-processes/produkte" component={() => !isLoading ? <DefaultProcessesProdukteView /> : null} />
					<RouteGuard exact path="/default-processes/produkte/create" component={() => !isLoading ? <DefaultProcessesProdukteCreateView /> : null} />
					<RouteGuard exact path="/default-processes/produkte/:productId/edit" component={() => !isLoading ? <DefaultProcessesProdukteEditView /> : null} />

					<RouteGuard exact path="/default-processes/kunden" component={() => !isLoading ? <DefaultProcessesKundenView /> : null} />
					<RouteGuard exact path="/default-processes/kunden/create" component={() => !isLoading ? <DefaultProcessesKundenCreateView /> : null} />
					<RouteGuard exact path="/default-processes/kunden/:customerId/edit" component={() => !isLoading ? <DefaultProcessesKundenEditView /> : null} />
					
					<RouteGuard exact path="/default-processes/buchungen" component={() => !isLoading ? <DefaultProcessesBuchungenView /> : null} />
					<RouteGuard exact path="/default-processes/interne-konten" component={() => !isLoading ? <DefaultProcessesInterneKontenView /> : null} />
					
					<RouteGuard exact path="/default-processes/manuelle-rechnung" component={() => !isLoading ? <DefaultProcessesManuelleRechnungView /> : null} />
					<RouteGuard exact path="/default-processes/developer" component={() => !isLoading ? <DefaultProcessesDeveloperView /> : null} />
					<RouteGuard exact path="/default-processes/developer/forms" component={() => !isLoading ? <DefaultProcessesDeveloperFormsView /> : null} />

					<RouteGuard exact path="/default-processes/rechnungen" component={() => !isLoading ? <DefaultProcessesRechnungenView /> : null} />
					<RouteGuard exact path="/default-processes/rechnungen/:orderId" component={() => !isLoading ? <DefaultProcessesRechnungenDetailsView /> : null} />
					<RouteGuard exact path="/default-processes/rechnungen/:orderId/edit" component={() => !isLoading ? <DefaultProcessesRechnungenEditView /> : null} />
					
					<RouteGuard exact path="/default-processes/archiv" component={() => !isLoading ? <DefaultProcessesArchivView /> : null} />
					<RouteGuard exact path="/default-processes/archiv/:folderId" component={() => !isLoading ? <DefaultProcessesArchivFolderView /> : null} />
					
					<RouteGuard exact path="/default-processes/service-center" component={() => !isLoading ? <DefaultProcessesServiceCenter /> : null} />
					
					<RouteGuard exact path="/default-processes/verwaltung" component={() => !isLoading ? <DefaultProcessesVerwaltungView /> : null} />
					<RouteGuard exact path="/default-processes/verwaltung/benutzer" component={() => !isLoading ? <DefaultProcessesVerwaltungBenutzerView /> : null} />
					<RouteGuard exact path="/default-processes/verwaltung/jahresabschluss" component={() => !isLoading ? <DefaultProcessesVerwaltungJahresabschlussView /> : null} />
					<RouteGuard exact path="/default-processes/verwaltung/benutzer/create" component={() => !isLoading ? <DefaultProcessesVerwaltungBenutzerCreateView /> : null} />
					<RouteGuard exact path="/default-processes/verwaltung/benutzer/create-permission" component={() => !isLoading ? <DefaultProcessesVerwaltungBenutzerCreatePermissionView /> : null} />
					<RouteGuard exact path="/default-processes/verwaltung/benutzer/:userId" component={() => !isLoading ? <DefaultProcessesVerwaltungBenutzerDetailsView /> : null} />
					
					
					<RouteGuard exact path="/custom-processes/kfz-kalkulator" component={() => !isLoading ? <CustomProcessesKfzKalkulatorView /> : null} />
					

					<Route exact path="/login" component={() => <LoginView />} />

					<Route exact path="/status/success" component={() => <ProcessStatusView status="success" />} />
					<Route exact path="/status/error" component={() => <ProcessStatusView status="error" />} />
					
					<Route exact path="/system-information" component={() => <SystemInformationView />} />
				</Switch>
			</Router>
		)
	);
}

export default App;