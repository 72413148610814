import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import formatPrice from '../../../utils/formatPrice';
import ContentBox from '../../../components/ContentBox';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';

function CustomProcessesKfzKalkulatorView() {

	let navigate = useHistory();
	const [directExit, setDirectExit] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showPriceEditor, setShowPriceEditor] = useState(false);
	
	const [inputHoehe, setInputHoehe] = useState(0);
	const [inputBreite, setInputBreite] = useState(0);
	const [inputFolienArt, setInputFolienArt] = useState("glaenzend"); // glaenzend, matt, digital
	const [inputKonturschnitt, setInputKonturschnitt] = useState(false);
	const [inputQualitaet, setInputQualitaet] = useState("standard"); // standard, hoch, polymerplus, gegossen
	const [inputSchutzschicht, setInputSchutzschicht] = useState(false);
	const [inputAnzahl, setInputAnzahl] = useState(1);


	/**
	 * Set price variations into a state, so the user could change it later.
	 * 
	 * Glänzend: 119,00 EUR pro m²
	 * Matt: 134,00 EUR pro m²
	 * Digital: 169,00 EUR pro m²
	 * 
	 * Konturschnitt: + 40,00 EUR pro m²
	 * 
	 * Folienqualität:
	 * Standard: + 0,00 EUR pro m²
	 * Hoch: + 10,00 EUR pro m²
	 * PolymerPlus: + 14,00 EUR pro m²
	 * Gegossen: + 19,00 EUR pro m²
	 * 
	 * Schutzschicht: + 14,00 EUR pro m²
	 * 
	 * Anzahl: Preis * Anzahl
	 */
	const [priceVariations, setPriceVariations] = useState({
		glaenzend: 119,
		matt: 134,
		digital: 169,
		konturschnitt: 40,
		standard: 0,
		hoch: 10,
		polymerplus: 14,
		gegossen: 19,
		schutzschicht: 14,
		anzahl: 1
	});

	const [price, setPrice] = useState(0);

	const calculatePrice = () => {
		let outputPrice = 0;

		// Calculate m²
		let hoehe = inputHoehe / 100;
		let breite = inputBreite / 100;
		console.log("hoehe", hoehe);
		console.log("breite", breite);

		let m2 = hoehe * breite;
		console.log("m2", m2);

		// Calculate folienart
		let folienart = priceVariations[inputFolienArt];
		console.log("folienart", folienart);
		outputPrice += (m2 * folienart);

		// Calculate konturschnitt
		if (inputKonturschnitt) {
			let konturschnitt = priceVariations["konturschnitt"];
			console.log("konturschnitt", konturschnitt);
			outputPrice += (m2 * konturschnitt);
		}

		// Calculate folienqualität
		let folienqualitaet = priceVariations[inputQualitaet];
		console.log("folienqualitaet", folienqualitaet);
		outputPrice += (m2 * folienqualitaet);

		// Calculate schutzschicht
		if (inputSchutzschicht) {
			let schutzschicht = priceVariations["schutzschicht"];
			console.log("schutzschicht", schutzschicht);
			outputPrice += (m2 * schutzschicht);
		}

		// Calculate anzahl
		let anzahl = inputAnzahl;
		outputPrice *= anzahl;

		/**
		 * Create backup of price variations and values of inputs into a local storage
		 */
		let data = {
			inputHoehe: inputHoehe,
			inputBreite: inputBreite,
			inputFolienArt: inputFolienArt,
			inputKonturschnitt: inputKonturschnitt,
			inputQualitaet: inputQualitaet,
			inputSchutzschicht: inputSchutzschicht,
			inputAnzahl: inputAnzahl,
			priceVariations: priceVariations
		};

		localStorage.setItem("custom-processes-kfz-kalkulator", JSON.stringify(data));
	

		setPrice(outputPrice);
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="car-1.jpg"
					title="KFZ-Bedruck-Kalkulator"
					text="Berechnen Sie die Kosten für die Bedruckung von KFZ-Beklebungen."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/");
					}}
					
					actionButtons={(price > 0) ? [
						{
							title: "Direkt-Rechnung",
							action: () => {
								setDirectExit(true);
								setShowLoader(true);navigate.push("/default-processes/manuelle-rechnung");
								let data = {
									"sku": "",
									"name": "KFZ Beklebung",
									"tax": "0",
									"count": "1",
									"unit_price": formatPrice(price),
									"total": formatPrice(price),
								}
								localStorage.setItem("directInvoice", JSON.stringify(data));
								setTimeout(() => {
									setShowLoader(false);
									navigate.push("/default-processes/manuelle-rechnung");
								}, 2000)
							}
						},
						{
							title: "Zurücksetzen",
							action: () => {
								setInputHoehe(0);
								setInputBreite(0);
								setInputFolienArt("glaenzend");
								setInputKonturschnitt(false);
								setInputQualitaet("standard");
								setInputSchutzschicht(false);
								setInputAnzahl(1);
								setPrice(0);
							}
						},
					] : (
						(showPriceEditor) ? [
							{
								title: "Preisparameter speichern",
								action: () => {
									setShowPriceEditor(false)
								}
							},
							{
								title: "Preisparameter zurücksetzen",
								action: () => {
									setPriceVariations({
										glaenzend: 119,
										matt: 134,
										digital: 169,
										konturschnitt: 40,
										standard: 0,
										hoch: 10,
										polymerplus: 14,
										gegossen: 19,
										schutzschicht: 14,
										anzahl: 1
									})
									setShowPriceEditor(false)
								}
							}
						] : [
							{
								title: "Preisparameter bearbeiten",
								action: () => {
									setShowPriceEditor(true)
								}
							}
						]
					)}

					hasSubmitButton={true}
					submitButtonTitle="Berechnen"
					submitButtonAction={calculatePrice}
				/>

				{!showPriceEditor ? (
					<ProcessSideContent>
						<ContentBox>
							<FormInput
								label="Höhe (cm)"
								value={inputHoehe}
								onChange={(e) => {
									setInputHoehe(e.target.value.replace(/[^0-9,.]/g, '').replace(",", "."));
								}}
								required
							/>
							<FormInput
								label="Breite (cm)"
								value={inputBreite}
								onChange={(e) => {
									setInputBreite(e.target.value.replace(/[^0-9,.]/g, '').replace(",", "."));
								}}
								required
							/>
							<FormInput
								label="= Quadratmeter"
								value={((inputHoehe * inputBreite) / 10000).toFixed(10).replace(".", ",")}
								disabled
							/>
						</ContentBox>
						<ContentBox>
							<FormSelect
								label="Folienart"
								value={inputFolienArt}
								onChange={(e) => {
									setInputFolienArt(e.target.value);
									setInputKonturschnitt(false);
								}}
								options={[
									{ value: "glaenzend", label: "Glänzend (" + formatPrice(priceVariations["glaenzend"]) + " EUR pro m²)" },
									{ value: "matt", label: "Matt (" + formatPrice(priceVariations["matt"]) + " EUR pro m²)" },
									{ value: "digital", label: "Digital (" + formatPrice(priceVariations["digital"]) + " EUR pro m²)" }
								]}
							/>
							{inputFolienArt === "digital" && (
								<FormCheckbox
									label={"Konturschnitt (+ " + formatPrice(priceVariations["konturschnitt"]) + " EUR pro m²)"}
									checked={inputKonturschnitt}
									onChange={(e) => {
										setInputKonturschnitt(!inputKonturschnitt);
									}}
									text="Konturschnitt"
								/>
							)}
						</ContentBox>
						<ContentBox>
							<FormSelect
								label="Folienqualität"
								value={inputQualitaet}
								onChange={(e) => {
									setInputQualitaet(e.target.value);
								}}
								options={[
									{ value: "standard", label: "Standard (kein Aufpreis)" },
									{ value: "hoch", label: "Hoch (+ " + formatPrice(priceVariations["hoch"]) + " EUR pro m²)" },
									{ value: "polymerplus", label: "PolymerPlus (+ " + formatPrice(priceVariations["polymerplus"]) + " EUR pro m²)" },
									{ value: "gegossen", label: "Gegossen (+ " + formatPrice(priceVariations["gegossen"]) + " EUR pro m²)" }
								]}
							/>
							<FormCheckbox
								label={"Schutzschicht (+ " + formatPrice(priceVariations["schutzschicht"]) + " EUR pro m²)"}
								checked={inputSchutzschicht}
								onChange={(e) => {
									setInputSchutzschicht(!inputSchutzschicht);
								}}
								text="Schutzschicht"
							/>
						</ContentBox>
						<ContentBox>
							<FormInput
								label="Anzahl"
								value={inputAnzahl}
								onChange={(e) => {
									setInputAnzahl(e.target.value.replace(/[^0-9,.]/g, '').replace(",", "."));
								}}
								required
							/>
						</ContentBox>
						{price > 0 && (
							<ContentBox>
								<FormInput
									label="= Preis"
									value={formatPrice(price)}
									disabled
								/>
							</ContentBox>
						)}
					</ProcessSideContent>
				) : (
					<ProcessSideContent>
						<ContentBox>
							<FormInput
								label="Quadratmeterpreis Glänzend"
								value={priceVariations["glaenzend"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										glaenzend: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
							<FormInput
								label="Quadratmeterpreis Matt"
								value={priceVariations["matt"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										matt: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
							<FormInput
								label="Quadratmeterpreis Digital"
								value={priceVariations["digital"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										digital: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
						</ContentBox>
						<ContentBox>
							<FormInput
								label="Quadratmeter-Aufpreis Konturschnitt"
								value={priceVariations["konturschnitt"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										konturschnitt: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
						</ContentBox>
						<ContentBox>
							<FormInput
								label="Quadratmeter-Aufpreis Folienqualität Standard"
								value={priceVariations["standard"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										standard: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
							<FormInput
								label="Quadratmeter-Aufpreis Folienqualität Hoch"
								value={priceVariations["hoch"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										hoch: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
							<FormInput
								label="Quadratmeter-Aufpreis Folienqualität PolymerPlus"
								value={priceVariations["polymerplus"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										polymerplus: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
							<FormInput
								label="Quadratmeter-Aufpreis Folienqualität Gegossen"
								value={priceVariations["gegossen"]}
								onChange={(e) => {
									// set the value into a variable. Filter the value and allow only numbers, dots and commas. Replace commas with dots
									setPriceVariations({
										...priceVariations,
										gegossen: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
						</ContentBox>
						<ContentBox>
							<FormInput
								label="Quadratmeter-Aufpreis Schutzschicht"
								value={priceVariations["schutzschicht"]}
								onChange={(e) => {
									setPriceVariations({
										...priceVariations,
										schutzschicht: e.target.value.replace(/[^0-9,.]/g, '').replace(",", ".")
									})
								}}
								required
							/>
						</ContentBox>
					</ProcessSideContent>
				)}

			</Content>
		</>
	);
}

export default CustomProcessesKfzKalkulatorView;