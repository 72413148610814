import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import ContentBox from '../../../components/ContentBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import axios from 'axios';
import formatPrice from '../../../utils/formatPrice';
import formatDate from '../../../utils/formatDate';
import Modal from '../../../components/Modal';
import FormSelect from '../../../components/FormSelect';
import FormCheckbox from '../../../components/FormCheckbox';
import FormInput from '../../../components/FormInput';

function DefaultProcessesRechnungenView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [bills, setBills] = useState([]);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [searchHideCancelled, setSearchHideCancelled] = useState(true);
	const [searchInvoiceNumber, setSearchInvoiceNumber] = useState("");
	const [searchCustomer, setSearchCustomer] = useState("");
	const [searchDate, setSearchDate] = useState("");
	const [searchAmount, setSearchAmount] = useState("");

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/bills?hideCancelled=true`).then((res) => {
			console.log(res.data);
			setBills(res.data);
			setShowLoader(false);
			console.log(res.data);
		})
	}, [])

	const handleSearch = () => {

		setShowLoader(true);

		let searchParams = {
			hideCancelled: searchHideCancelled,
			invoiceNumber: searchInvoiceNumber,
			customer: searchCustomer,
			date: searchDate,
			amount: searchAmount
		}

		axios.get(`${process.env.REACT_APP_API_URL}/api/bills?${new URLSearchParams(searchParams)}`).then((res) => {
			console.log(res.data);
			setBills(res.data);
			setShowLoader(false);
			setShowSearchModal(false);
			console.log(res.data);
		})

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="orders-1.jpg"
					title="Rechnungen verwalten"
					text="Verwalten Sie Ihre Rechnungen und erhalten Sie eine Übersicht."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}

					actionButtons={[
						{
							title: "Rechnung erstellen",
							action: () => {
								navigate.push("/default-processes/manuelle-rechnung")
							}
						},
						{
							title: "Rechnung suchen",
							action: () => {
								setShowSearchModal(true)
							}
						}
					]}
				/>

				<ProcessSideContent>
					{bills.length === 0 ? (
						<div className="Empty-List">
							<h3>Es sind keine Rechnungen vorhanden.</h3>
							<i>DP_RECHNUNGEN_VIEW_EMPTY</i>
						</div>
					) : (
						<ContentBox>
							<div className="DefaultProcesses-Rechnungen-TableContainer">
								<table className="DefaultProcesses-Rechnungen-Table">
									<thead>
										<tr>
											<th align="left"></th>
											<th align="left">#</th>
											<th align="left">Datum</th>
											<th align="left">Kunde</th>
											<th align="left">Art</th>
											<th align="left">Status</th>
											<th align="right">Betrag</th>
											<th align="left"></th>
										</tr>
									</thead>
									<tbody>
										{bills.map((bill, index) => (
											<tr key={index}>
												<td align="left">
													{bill.bill_is_cancelled === 1 ? 
														(
															<span className="material-symbols-rounded invoice-icon invoice-icon--canceled">
																do_not_disturb_on
															</span>
														)
													: (
														bill.bill_is_paid === 1 ?
														(
															<span className="material-symbols-rounded invoice-icon invoice-icon--paid">
																credit_card
															</span>
														) : 
														(
															<span className="material-symbols-rounded invoice-icon invoice-icon--unpaid">
																credit_card_off
															</span>
														)
													)}
												</td>
												<td align="left">{bill.bill_identifier}</td>
												<td align="left">{formatDate(bill.created_at, true)}</td>
												<td align="left">
													{
														(bill.customer?.company_name === "./." ? "[P] " + bill.customer?.firstname.replace("./.","") + " " + bill.customer?.lastname.replace("./.","") : "[U] " + (bill.bill_recipient_name || "Unbekannt!"))
													}
												</td>
												<td align="left">{
													(bill.bill_type === "outgoing_invoice") ? "Ausgehende RE" : 
													(bill.bill_type === "incoming_invoice") ? "Eingehende Re" :
													(bill.bill_type === "internal_transfer") ? "Interne Umbuchung" :
													(bill.bill_type === "cancellation") ? "Stornobuchung" :
													(bill.bill_type === "offer") ? "Angebot" :
													(bill.bill_type === "periodic") ? "Periodische Buchung (mtl.)" : "Unbekannt (" + bill.bill_type + ")"													
												}</td>
												<td align="left">
													{
														bill.bill_is_cancelled === 1 ? "Storniert" :
														bill.bill_is_paid === 1 ? "Bezahlt" : 
														(bill.bill_type === "offer" ? "Keine Zahlung notwendig" : "Offener Rechnungsbetrag")
													}
												</td>
												<td align="right">{formatPrice(bill.bill_total)}</td>
												<td align="left">
													<Link to={`/default-processes/rechnungen/${bill.id}`}>
														<span className="material-symbols-rounded">
															chevron_right
														</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</ContentBox>
					)}
				</ProcessSideContent>
			</Content>

			{showSearchModal && (
				<Modal
					title="Rechnung suchen"
					isClosable={true}
					closeAction={() => {
						setShowSearchModal(false);
					}}

					actionButtons={[
						{
							title: "Suchen",
							action: () => {
								handleSearch();
							}
						}
					]}
				>

					<FormInput
						label="Beleg-Nr."
						type="text"
						value={searchInvoiceNumber}
						onChange={(e) => {setSearchInvoiceNumber(e.target.value)}}
					/>

					<FormInput
						label="Kunde"
						type="text"
						value={searchCustomer}
						onChange={(e) => {setSearchCustomer(e.target.value)}}
					/>

					<FormInput
						label="Rechnungsdatum"
						type="text"
						value={searchDate}
						customOnChange={(e) => {setSearchDate(e.target.value)}}
						formatdate={true}
						placeholder="Optional (DD.MM.JJJJ)"
					/>

					<FormInput
						label="Summe"
						type="text"
						value={searchAmount}
						onChange={(e) => {setSearchAmount(e.target.value)}}
						formatprice
					/>

					<FormCheckbox
						label="Stornierte Rechnungen"
						checked={searchHideCancelled}
						onChange={(e) => {
							setSearchHideCancelled(e);
						}}
						text="Ausblenden"
					/>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesRechnungenView;