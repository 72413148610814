import React, {useEffect,useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Navigation from '../../components/Navigation';
import Content from '../../components/Content';
import Loader from '../../components/Loader';

// Utils
import checkPermission from '../../utils/checkPermission';

function HomeView() {

	const [showLoader, setShowLoader] = useState(true);
	const [homeItems, setHomeItems] = useState([]);
	const navigate = useHistory();

	useEffect(() => {
		// Get user ID
		const data = localStorage.getItem("data");
		const parsedData = JSON.parse(data);
		const userId = parsedData.id;

		// Get user permissions
		axios.get(`${process.env.REACT_APP_API_URL}/api/permissions/${userId}`)
			.then((res) => {
				setShowLoader(false);
				let tempHomeItems = [];

				res.data.data.forEach((item) => {

					if (item.permission_key === "PROZ-START-RECHNUNGEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Rechnungen",
							icon: "request_quote",
							link: "/default-processes/rechnungen"
						})
					}

					if (item.permission_key === "PROZ-START-PRODUKTE" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Produkte",
							icon: "archive",
							link: "/default-processes/produkte"
						})
					}

					if (item.permission_key === "PROZ-START-KUNDEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kunden",
							icon: "people",
							link: "/default-processes/kunden"
						})
					}

					if (item.permission_key === "PROZ-START-SERVICE-CENTER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Service Center",
							icon: "support_agent",
							link: "/default-processes/service-center"
						})
					}

					//KUNDENSTAMM
					if (item.permission_key === "PROZ-START-KUNDENSTAMM" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kundenstamm",
							icon: "donut_large",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-RABATTE" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Rabatte",
							icon: "sell",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-MANUELLE-RECHNUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Man. Rechnung",
							icon: "receipt_long",
							link: "/default-processes/manuelle-rechnung"
						})
					}

					if (item.permission_key === "PROZ-START-VERWALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Verwaltung",
							icon: "tune",
							link: "/default-processes/verwaltung"
						})
					}

					if (item.permission_key === "PROZ-START-ARCHIV" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Archiv",
							icon: "folder_copy",
							link: "/default-processes/archiv"
						})
					}

					if (item.permission_key === "PROZ-START-PERSONAL" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Personal",
							icon: "badge",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-LAGER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Lager",
							icon: "inventory_2",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-BUCHUNGEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Buchungen",
							icon: "compare_arrows",
							link: "/default-processes/buchungen"
						})
					}

					if (item.permission_key === "PROZ-START-BANK" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Bank",
							icon: "account_balance",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-INTERNE-KONTEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Interne Konten",
							icon: "account_balance_wallet",
							link: "/default-processes/interne-konten"
						})
					}

					if (item.permission_key === "PROZ-START-ENTWICKLER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Entwickler",
							icon: "api",
							link: "/default-processes/developer"
						})
					}

					if (item.permission_key === "PROZ-START-CUSTOM-KFZ-KALKULATOR" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "KFZ-Bedruck-Kalkulator",
							icon: "directions_car",
							link: "/custom-processes/kfz-kalkulator"
						})
					}



					// Prozess 1
					if (item.permission_key === "PROZ-START-AUFTRAGSVERWALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Auftragsverwaltung",
							icon: "folder_copy",
							link: "/default-processes/auftragsverwaltung"
						})
					}

					// Prozess 2
					if (item.permission_key === "PROZ-START-LAGERVERWALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Lagerverwaltung",
							icon: "archive",
							link: "/default-processes/lagerverwaltung"
						})
					}

					// Prozess 3
					if (item.permission_key === "PROZ-START-LIEFERANTENMANAGEMENT" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Lieferantenmanagement",
							icon: "group",
							link: "/default-processes/lieferantenmanagement"
						})
					}

					// Prozess 4
					if (item.permission_key === "PROZ-START-PERSONALWESEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Personalwesen",
							icon: "supervisor_account",
							link: "/default-processes/personalwesen"
						})
					}

					// Prozess 5
					if (item.permission_key === "PROZ-START-MARKETING-UND-VERTRIEB" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Marketing und Vertrieb",
							icon: "storefront",
							link: "/default-processes/marketing-und-vertrieb"
						})
					}

					// Prozess 6
					if (item.permission_key === "PROZ-START-PROJEKTMANAGEMENT" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Projektmanagement",
							icon: "gavel",
							link: "/default-processes/projektmanagement"
						})
					}

					// Prozess 7
					if (item.permission_key === "PROZ-START-KUNDENBETREUUNG-UND-SUPPORT" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kundenbetreuung und Support",
							icon: "contact_support",
							link: "/default-processes/kundenbetreuung-und-support"
						})
					}

					// Prozess 8
					if (item.permission_key === "PROZ-START-FINANZ-UND-BUCHHALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Finanz- und Buchhaltungswesen",
							icon: "attach_money",
							link: "/default-processes/finanz-und-buchhaltung"
						})
					}

					// Prozess 9
					if (item.permission_key === "PROZ-START-BESTELLWESEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Bestellwesen",
							icon: "shopping_cart",
							link: "/default-processes/bestellwesen"
						})
					}

					// Prozess 10
					if (item.permission_key === "PROZ-START-PRODUKTIONSPLANUNG-UND-STEUERUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Produktionsplanung und -steuerung",
							icon: "build",
							link: "/default-processes/produktionsplanung-und-steuerung"
						})
					}

					// Prozess 11
					if (item.permission_key === "PROZ-START-KUNDENANFRAGEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kundenanfragen",
							icon: "live_help",
							link: "/default-processes/kundenanfragen"
						})
					}

					// Prozess 12
					if (item.permission_key === "PROZ-START-REKLAMATIONEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Reklamationen",
							icon: "report_problem",
							link: "/default-processes/reklamationen"
						})
					}

					// Prozess 13
					if (item.permission_key === "PROZ-START-VERTRAGSVERWALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Vertragsverwaltung",
							icon: "description",
							link: "/default-processes/vertragsverwaltung"
						})
					}

					// Prozess 14
					if (item.permission_key === "PROZ-START-BESCHAFFUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Beschaffung",
							icon: "shopping_basket",
							link: "/default-processes/beschaffung"
						})
					}

					// Prozess 15
					if (item.permission_key === "PROZ-START-QUALITÄTSSICHERUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Qualitätssicherung",
							icon: "thumb_up",
							link: "/default-processes/qualitaetssicherung"
						})
					}
					

				})

				setHomeItems(tempHomeItems);

			})
			.catch((err) => {
				navigate.push("/status/error");
			})

	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<div className="Home-Grid">


					{homeItems.map((item, index) => (
						<Link to={item.link} key={index} className="Home-Grid-Item">
							<div className="Home-Grid-Item-Container">
								<span className="material-symbols-rounded">
									{item.icon}
								</span>
							</div>
							<span className="Home-Grid-Item-Title">{item.title}</span>
						</Link>
					))}

				</div>
			</Content>
		</>
	);
}

export default HomeView;